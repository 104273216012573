<template>
  <InformationAppModal
    class="new-look-modal"
    :icon="ICON_TYPE.STARS"
    :shouldDisplayCloseButton="true"
    @close="hideXeNewLookModal"
    :value="showXeNewLookModal"
  >
    <h1 class="modal-title">{{ $t('XeNewLookModal.ModalTitle').value }}</h1>
    <p class="modal-text">
      {{ $t('XeNewLookModal.ModalText').value }}
    </p>

    <template #footer>
      <AppLink
        class="link-text"
        analytics-name="xe-new-look-modal-link"
        @click="onHelpCenterClick"
        v-html="$t('XeNewLookModal.ModalLinkText').value"
      >
      </AppLink>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'

import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, AppLink } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'


import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import {useI18nStore,  useAuthStore, useAnalyticsStore, useAppStore, useDeviceStore } from '@galileo/stores'

export default {
  name: 'XeNewLookModal',
  components: {
    InformationAppModal,
    AppButton,
    AppLink,
  },
  setup() {
    const { $t } = useI18nStore()
    const deviceStore = useDeviceStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()

    const user = authStore.user
    const { isCorporateAccount } = storeToRefs(authStore)

    const fxWebCorpMigrated = user.fxWebCorpMigrated

    const showXeNewLookModal = computed(() => {
      const newLookModalAlreadyDisplayed = deviceStore.getNewLookModalAlreadyDisplayed
      const isModalOpen =
        isCorporateAccount.value && fxWebCorpMigrated && !newLookModalAlreadyDisplayed
      if (isModalOpen) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.NEW_SITE_PROMPT_DISPLAYED,
        })
      }
      return isModalOpen
    })

    const hideXeNewLookModal = () => {
      deviceStore.setNewLookModalAlreadyDisplayed(true)
    }
    const onHelpCenterClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.INTRODUCING_THE_NEW_XE_WEB_EXPERIENCE,
        article: HelpDesk[HelpDeskProperties.INTRODUCING_THE_NEW_XE_WEB_EXPERIENCE],
      })
    }

    return {
      $t,
      showXeNewLookModal,
      hideXeNewLookModal,
      onHelpCenterClick,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
::v-deep .overlay-content .card {
  max-width: 37rem;
}
::v-deep .card-header {
  @apply px-12;
}
::v-deep .card-header .card-header-title {
  @apply h-20 mt-12;
}
::v-deep .card-header .card-header-right {
  @apply self-start;
}
::v-deep .card-footer {
  @apply items-center;
}
.modal-title {
  @apply text-3xl font-semibold;
}
.modal-text {
  @apply text-sm font-normal tracking-normal text-center;
}
::v-deep .text-underline {
  @apply underline;
}
::v-deep .link {
  @apply text-base font-bold;
  max-width: fit-content;
}
</style>
