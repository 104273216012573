import { reactive, computed, watch } from '@vue/composition-api'
import { toRef } from '@vue/composition-api'
import { useValidation } from 'vue-composable'
import { useRecipientsStore } from '@galileo/stores'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

export default function () {
  const valueRequired = getValueRequiredValidation()
  const industryCategoryType = reactive({
    categories: [],
    selectedCategory: null,
    types: [],
    filteredTypes: computed(() => {
      if (industryCategoryType.selectedCategory === '') {
        return []
      }
      return industryCategoryType.types.filter(
        (category) => category.industryCategoryId == industryCategoryType.selectedCategory
      )
    }),
    selectedType: '',
    show: false,
  })

  industryCategoryType.validation = useValidation({
    industryCategory: {
      $value: toRef(industryCategoryType, 'selectedCategory'),
      valueRequired,
    },
    industryType: {
      $value: toRef(industryCategoryType, 'selectedType'),
      valueRequired,
    },
  })

  //watcher to reset the industry type selected when selecting new category
  watch(
    () => industryCategoryType['selectedCategory'],
    (newVal, oldVal) => {
      if (oldVal !== null) {
        if (newVal !== oldVal && industryCategoryType.selectedType !== '') {
          industryCategoryType.selectedType = ''
        }
      }
    }
  )

  //Revisit the logic here, it is mandatory to have industry type?
  const setupIndustryTypeFromRecipient = async (recipient) => {
    let recipientsIndustryType = parseInt(recipient.industryTypeId)

    //All accounts that are businesses and have value on industry type should display it
    if (recipient.isBusinessAccount && recipient.industryTypeId) {
      industryCategoryType.show = true
    } else {
      industryCategoryType.show = false
    }
    return getIndustryCategoryType(recipientsIndustryType)
  }

  const setupIndustryType = async (
    fieldsDefinition,
    isBusinessAccount,
    industryTypeValue = null
  ) => {
    const isIndustryTypeRequired = fieldsDefinition.find((field) => field.id === 'INDUSTRY_TYPE')

    if (industryTypeValue != null) {
      industryTypeValue = parseInt(industryTypeValue)
    }

    //show industryType only if it is a business account AND fields definition contains the industry type field.

    if (isBusinessAccount && isIndustryTypeRequired) {
      industryCategoryType.show = true
    } else {
      industryCategoryType.show = false
    }
    return getIndustryCategoryType(industryTypeValue)
  }

  const getIndustryCategoryType = async (recipientsIndustryType = null) => {
    const recipientsStore = useRecipientsStore()

    //if need tyo show prepopulate industry type dropdownsprops
    if (industryCategoryType.show) {
      industryCategoryType.categories = (await recipientsStore.getIndustryCategories()).data

      industryCategoryType.types = (await recipientsStore.getIndustryTypesByCategory()).data

      if (recipientsIndustryType) {
        let selectedIndustryType = industryCategoryType.types.find(
          (type) => type.industryTypeId === recipientsIndustryType
        )

        if (selectedIndustryType) {
          industryCategoryType.selectedCategory = selectedIndustryType.industryCategoryId
          industryCategoryType.selectedType = recipientsIndustryType
        }
      }
    }
    return industryCategoryType
  }

  const addIndustryTypeAsField = (fields) => {
    if (industryCategoryType.show) {
      fields.push({
        name: 'INDUSTRY_TYPE',
        value: industryCategoryType.selectedType,
      })
    }
  }

  return {
    setupIndustryType,
    setupIndustryTypeFromRecipient,
    addIndustryTypeAsField,
    selectedIndustryType: computed(() => industryCategoryType.selectedType),
  }
}
