<template>
  <InformationAppModal icon="warningYellow" :value="showCancelTransfer">
    <h1>{{ $t('ComponentModifyRecipientModal.TitleCancelDialog').value }}</h1>

    <p>{{ $t('ComponentModifyRecipientModal.CancelDialogInfo').value }}</p>

    <template #footer>
      <AppButton
        analytics-name="activity-cancel-transfer-yes"
        :loading="loading"
        @click="cancelTransferAction"
      >
        {{ $t('PageActivity.CancelButtonYes').value }}
      </AppButton>

      <AppButton
        :disabled="loading"
        analytics-name="activity-cancel-transfer-no"
        theme="text"
        @click="hideCancelTransfer"
      >
        {{ $t('PageActivity.CancelButtonNo').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
// Stores
import {
  useI18nStore,
  useTransactionStore,
  useAnalyticsStore,
  useAppStore,
  useActivityStore,
} from '@galileo/stores'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

// Components
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'
import { computed } from '@vue/composition-api'

export default {
  name: 'CancelTransferDialog',
  components: {
    InformationAppModal,
    AppButton,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const transactionStore = useTransactionStore()
    const analyticsStore = useAnalyticsStore()
    const appStore = useAppStore()
    const activityStore = useActivityStore()

    const cancelTransferAction = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSACTION_CANCELLATION_REQUESTED,
        traits: {
          location: SEGMENT_LOCATIONS.TRANSFER_DETAILS,
        },
      })

      transactionStore.setActivityLoading()

      try {
        await activityStore.cancelTransaction(transactionStore.selectedTransaction.orderNumber)

        transactionStore.loadingDetails = false
        transactionStore.hideSelectedTransactionModal()
        transactionStore.showCancellationSuccessful = true

        transactionStore.loading = true
        activityStore.reloadTransactions()
      } catch (ex) {
        transactionStore.showCancellationFailed = true
        appStore.logException('Exception during cancel transfer', ex)
      } finally {
        transactionStore.hideCancelTransactionDialog()
        transactionStore.setActivityReady()
        transactionStore.loading = false
      }
    }

    const hideCancelTransfer = () => {
      transactionStore.hideCancelTransactionDialog()
    }

    return {
      $t,
      cancelTransferAction,
      hideCancelTransfer,
      loading: computed(() => transactionStore.loadingDetails),
      showCancelTransfer: computed(() => transactionStore.showCancelTransfer),
    }
  },
}
</script>
