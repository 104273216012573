<template>
  <div>
    <h2 class="type-overline text-tertiary-text activity-list-title">
      {{ category.title }}
      <div v-if="category.dot" class="activity-dot"></div>
    </h2>

    <AppList class="xe-category-list activity-category-list">
      <EllipsisWrapper
        v-for="transaction in category.transactions"
        :key="transaction.id"
        :value="transaction.recipientFullName"
        no-resize
      >
        <AppListItem
          :analitycs-name="'transaction' + '-' + transaction.id"
          :class="'activity-theme-' + category.theme"
          @itemClick="$emit('selectTransaction', transaction)"
        >
          <template #figure>
            <AppFigure class="xe-figure" theme="gray">
              <img :src="category.icon" :alt="`${category.title} icon`" />
            </AppFigure>
          </template>

          <template #left>
            <AppListItemTitle>
              <span>
                <EllipsisWrapper
                  v-if="transaction.recipientFullName"
                  :value="transaction.recipientFullName"
                >
                  <span class="font-bold"> {{ transaction.recipientFullName }}</span>
                </EllipsisWrapper>

                <span v-else class="font-bold">
                  {{ $t('PageActivity.TextContract').value }}
                  {{ transaction.orderNumber }}
                  <span class="text-tertiary-text"> · </span>
                </span>

                <span class="text-tertiary-text">
                  {{ format(transaction.dateCreated).value }}
                </span>
              </span>
            </AppListItemTitle>

            <AppListItemCaption>
              <div v-if="transaction.amountFrom" class="text-secondary-text">
                {{ transaction.amountFrom | currency(transaction.currencyFrom) }}
                ›
                {{ transaction.amountTo | currency(transaction.currencyTo) }}
              </div>

              <div v-if="!transaction.amountFrom" class="text-secondary-text">
                {{ transaction.amountTo | currency(transaction.currencyTo) }}
              </div>

              <div class="status-text">{{ $t(transaction.statusKey).value }}</div>
            </AppListItemCaption>
          </template>

          <template #action>
            <div class="activity-buttons">
              <!-- <AppButton
              v-if="transaction.button === buttons.Verify"
              theme="secondary"
              analytics-name="activity-verify"
              @click="verifyTransaction(transaction)"
            >
              {{ $t('PageActivity.ButtonVerify').value }}
            </AppButton> -->

              <AppButton
                v-if="transaction.button === buttons.Details"
                theme="secondary"
                analytics-name="activity-details"
                @click="$emit('selectTransaction', transaction)"
              >
                {{ $t('PageActivity.ButtonDetails').value }}
              </AppButton>

              <AppButton
                v-if="transaction.button === buttons.Resume"
                theme="secondary"
                analytics-name="activity-details"
                @click="$emit('selectTransaction', transaction)"
              >
                {{ $t('PageActivity.ButtonResume').value }}
              </AppButton>

              <AppButton
                v-if="transaction.button === buttons.ProvideInfo"
                theme="secondary"
                analytics-name="activity-provide-info"
                @click="$emit('provideInfo', transaction)"
              >
                {{ $t('PageActivity.ButtonProvideInfo').value }}
              </AppButton>

              <!-- <AppButton
              v-if="transaction.button === buttons.Continue"
              theme="secondary"
              analytics-name="activity-continue"
              @click="continueTransaction(transaction)"
            >
              {{ $t('PageActivity.ButtonContinue').value }}
            </AppButton> -->

              <AppButton
                v-if="transaction.button === buttons.Share"
                theme="secondary"
                analytics-name="activity-share"
                @click="showShareFromTransactionsList(transaction)"
              >
                {{ $t('PageActivity.ButtonShare').value }}
              </AppButton>

              <AppButton
                :loading="loadingTransactionId === transaction.id"
                v-if="transaction.button === buttons.Resend"
                theme="secondary"
                analytics-name="activity-resend"
                @click="$emit('sendAgain', transaction)"
              >
                {{ $t('PageActivity.ButtonResend').value }}
              </AppButton>

              <AppButton
                v-if="transaction.button === buttons.TryAgain"
                theme="secondary"
                analytics-name="activity-tryagain"
                @click="$emit('sendAgain', transaction)"
              >
                {{ $t('PageActivity.ButtonTryAgain').value }}
              </AppButton>
            </div>
          </template>
        </AppListItem>
      </EllipsisWrapper>
    </AppList>
  </div>
</template>

<script>
import {
  AppButton,
  AppFigure,
  AppList,
  AppListItem,
  AppListItemCaption,
  AppListItemTitle,
} from '@oen.web.vue2/ui'

import { ref } from '@vue/composition-api'
import { useIntlDateTimeFormat } from 'vue-composable'

import { storeToRefs } from 'pinia'

import { getLocaleFromCountry } from '@galileo/composables/useLocale'

import { defineComponent } from '@vue/composition-api'

import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'

import { useRouter } from '@galileo/composables/useRouter'

import { useActivityStore, useAuthStore, useI18nStore, useTransactionStore } from '@galileo/stores'

export default defineComponent({
  components: {
    AppButton,
    AppFigure,
    AppList,
    AppListItem,
    AppListItemCaption,
    AppListItemTitle,
    EllipsisWrapper,
  },
  props: {
    category: {
      type: Object,
      required: true,
      default: () => {},
    },
    showShare: {
      type: Function,
    },
    loadingTransactionId: {
      type: String,
      required: false,
      default: '',
    },
  },
  events: ['showShare', 'provideInfo', 'sendAgain', 'selectTransaction', 'changeLoadingShareState'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const activityStore = useActivityStore()

    const buttons = useTransactionStore().buttons

    const { user } = storeToRefs(authStore)

    // setting dates based on the user country language
    let userCountry = user.value?.country
    // we fallback to en-US if userLocale is undefined
    const selectedLocale = ref(getLocaleFromCountry(userCountry))
    const { format } = useIntlDateTimeFormat(selectedLocale, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })

    const showShareFromTransactionsList = async (transaction) => {
      emit('changeLoadingShareState', true)

      const trxDetails = await activityStore.getTransactionDetails(transaction.orderNumber)
      emit('changeLoadingShareState', false)
      emit('showShare', trxDetails)
    }

    return {
      $t,
      buttons,
      format,
      showShareFromTransactionsList,
    }
  },
})
</script>

<style scoped>
::v-deep .activity-buttons {
  .button {
    max-width: fit-content !important;
  }
}

::v-deep .list-item-title {
  @apply font-medium;
}

::v-deep .list-item-caption {
  @apply font-normal;
}

.activity-category-list {
  @apply border-t-0 border-b-0;

  .activity-buttons {
    .button {
      ::v-deep .button-inner {
        @apply px-6 py-3 text-sm !important;
      }
      ::v-deep .md-ripple {
        @apply z-0;
      }
    }
  }
}

/* fixing hover for applist*/
::v-deep .activity-category-list .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
  font-size: 14px;
}

.activity-list-title {
  @apply tracking-wider;
  display: flex;
  align-items: center;
  font-size: 10px;
}

.activity-dot {
  display: inline-block;
  margin-left: 8px;
  width: 7px;
  height: 7px;
  border-radius: 100px;
  @apply bg-yellow;
}

.button--gray {
  @apply bg-gray-tertiary text-primary-text;
}

/* Activity buttons - Themes */
::v-deep .activity-theme-yellow {
  .button {
    .button-inner {
      background-color: var(
        --theme-activity-button-action-required-background-color,
        var(--theme-color-secondary, theme('colors.yellow.lighter'))
      );
      color: var(
        --theme-activity-button-action-required-text-color,
        var(--theme-color-secondary, theme('colors.yellow.text'))
      );
    }
    border: var(--theme-activity-button-in-progress-border, none);

    &:hover {
      .button-inner {
        background-color: var(
          --theme-activity-button-action-required-hover-background-color,
          var(--theme-color-secondary, theme('colors.yellow.lighter'))
        );
      }
    }
  }
  .status-text {
    color: var(
      --theme-activity-button-action-required-status-text-color,
      var(--theme-color-secondary, theme('colors.yellow.text'))
    );
  }
}

::v-deep .activity-theme-blue {
  .button {
    border: var(--theme-activity-button-in-progress-border, none);
    .button-inner {
      background-color: var(
        --theme-activity-button-in-progress-background-color,
        var(--theme-color-secondary, theme('colors.blue.lighter'))
      );
      color: var(
        --theme-activity-button-in-progress-text-color,
        var(--theme-color-secondary, theme('colors.blue.text'))
      );
    }

    &:hover {
      .button-inner {
        background-color: var(
          --theme-activity-button-in-progress-hover-background-color,
          var(--theme-color-secondary, theme('colors.blue.lighter'))
        );
      }
    }
  }
  .status-text {
    color: var(
      --theme-activity-button-in-progress-status-text-color,
      var(--theme-color-secondary, theme('colors.blue.text'))
    );
  }
}

::v-deep .activity-theme-green {
  .button {
    border: var(--theme-activity-button-in-progress-border, none);
    .button-inner {
      background-color: var(
        --theme-activity-button-completed-background-color,
        var(--theme-color-secondary, theme('colors.green.lighter'))
      );
      color: var(
        --theme-activity-button-completed-text-color,
        var(--theme-color-secondary, theme('colors.green.text'))
      );
    }

    &:hover {
      .button-inner {
        background-color: var(
          --theme-activity-button-completed-hover-background-color,
          var(--theme-color-secondary, theme('colors.green.lighter'))
        );
      }
    }
  }
  .status-text {
    color: var(
      --theme-activity-button-completed-status-text-color,
      var(--theme-color-secondary, theme('colors.green.text'))
    );
  }
}

::v-deep .activity-theme-red {
  .button {
    border: var(--theme-activity-button-in-progress-border, none);
    .button-inner {
      background-color: var(
        --theme-activity-button-cancelled-background-color,
        var(--theme-color-secondary, theme('colors.red.lighter'))
      );
      color: var(
        --theme-activity-button-cancelled-text-color,
        var(--theme-color-secondary, theme('colors.red.text'))
      );
    }

    &:hover {
      .button-inner {
        background-color: var(
          --theme-activity-button-cancelled-hover-background-color,
          var(--theme-color-secondary, theme('colors.red.lighter'))
        );
      }
    }
  }
  .status-text {
    color: var(
      --theme-activity-button-cancelled-status-text-color,
      var(--theme-color-secondary, theme('colors.red.text'))
    );
  }
}
</style>
