<template>
  <InformationAppModal :value="shouldDisplayModal" icon="warningYellow">
    <template>
      <h1 class="title">
        {{ $t('PaymentMethodUnavailableModal.Title').value }}
      </h1>

      <p>
        {{ $t('PaymentMethodUnavailableModal.ParagraphText').value }}
      </p>
    </template>

    <template #footer>
      <AppButton @click="changePaymentMethod" :loading="loading">
        {{ $t('PaymentMethodUnavailableModal.ChangePaymentMethodButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeModal">
        {{ $t('PaymentMethodUnavailableModal.DismissButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { AppButton } from '@oen.web.vue2/ui'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { useRouter } from '@galileo/composables/useRouter'

import {
  useAnalyticsStore,
  useI18nStore,
  useSendMoneyStore,
  useAuthStore,
  useActivityStore,
} from '@galileo/stores'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default defineComponent({
  name: 'PaymentMethodUnavailableModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const activityStore = useActivityStore()

    let shouldDisplayModal = computed(
      () => sendMoneyStore.shouldDisplayPaymentMethodUnavailableModal
    )

    const loading = ref(false)
    const changePaymentMethod = async () => {
      loading.value = true
      analyticsStore.track({
        event: SEGMENT_EVENTS.REPEAT_PAYMENT_RESUMED,
        traits: {
          paymentMethod: activityStore.transactionDetails.paymentMethod.value,
          location: SEGMENT_LOCATIONS.ACTIVITY,
        },
      })

      await sendMoneyStore.calculate({ summary: false, debounceTime: 0 })
      router.replace('/send-money/payment')
      loading.value = false
    }

    const closeModal = () => {
      sendMoneyStore.shouldDisplayPaymentMethodUnavailableModal = false
    }

    return { changePaymentMethod, closeModal, $t, shouldDisplayModal, loading }
  },
})
</script>

<style scoped>
::v-deep .card-header {
  @apply mb-9 py-0;
  .card-header-title {
    @apply h-14;
    svg {
      @apply h-14 w-14;
    }
  }
}

::v-deep .card-content {
  .card-content-block {
    @apply mb-8;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply py-12;
  }
}

::v-deep .card-footer .button--secondary {
  @screen sm {
    @apply my-0 !important;
  }
}

::v-deep .card-footer .button--primary {
  @screen sm {
    @apply mb-6 !important;
  }
}

::v-deep .modal-body {
  @apply mb-8;
  p {
    @apply mt-4 text-base leading-6;
  }
}
</style>
