<template>
  <InformationAppModal icon="crossmark" :value="showCancellationFailed">
    <h1>{{ $t('PageActivity.TitleCancelFailedDialog').value }}</h1>

    <p>{{ $t('PageActivity.CancelDialogFailedInfo').value }}</p>

    <template #footer>
      <AppButton
        analytics-name="activity-cancel-failed-help"
        :loading="loadingHelpDesk"
        @click="showHelpDesk"
      >
        {{ $t('PageActivity.CancelFailedButtonHelp').value }}
      </AppButton>

      <AppButton
        analytics-name="activity-cancel-failed-ok"
        theme="text"
        @click="hideCancellationFailed"
      >
        {{ $t('PageActivity.CancelFailedButtonOk').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

// Stores
import { useI18nStore, useAppStore, useTransactionStore } from '@galileo/stores'

// Components
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'CancellationFailedDialog',
  components: {
    InformationAppModal,
    AppButton,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const transactionStore = useTransactionStore()

    const loadingHelpDesk = ref(false)

    const showHelpDesk = () => {
      appStore.openHelpDesk({ loadingRef: loadingHelpDesk })
    }

    const hideCancellationFailed = () => {
      transactionStore.showCancellationFailed = false
      transactionStore.showEditTransaction = false
    }

    return {
      $t,
      showCancellationFailed: computed(() => transactionStore.showCancellationFailed),
      hideCancellationFailed,
      loadingHelpDesk,
      showHelpDesk,
    }
  },
}
</script>

<style></style>
