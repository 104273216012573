<template>
  <div class="no-activity-wrapper">
    <h3 class="no-activity-heading">{{ $t('NoActivity.Heading').value }}</h3>
    <p class="no-activity-text">{{ $t('NoActivity.Copy').value }}</p>
    <router-link v-if="isQuickTransferEnabled" to="/quick-transfer" class="router-link">
      <p class="router-link-text">{{ $t('NoActivity.QuickTransferLink').value }}</p>
    </router-link>
    <router-link v-else to="/send-money" class="router-link">
      <p class="router-link-text">{{ $t('PageActivity.ButtonSendMoney').value }}</p>
    </router-link>
  </div>
</template>

<script>
import { useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'CorporateEmptyTransactionList',

  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()

    return { $t, isQuickTransferEnabled: authStore.getIsQuickTransferEnabled }
  },
}
</script>

<style scoped>
.no-activity-wrapper {
  @apply flex flex-col text-center mx-3 mb-10 mt-4  leading-5 items-center bg-white rounded-xl p-8;
  @screen sm {
    @apply mx-0 mt-6 p-12;
  }
}
.no-activity-heading {
  @apply font-semibold mb-2 text-base text-grayblue-400;
  @screen sm {
    @apply text-lg;
  }
}
.no-activity-text {
  @apply text-xs text-grayblue-300 mb-6;
  @screen sm {
    @apply text-sm;
  }
}
.router-link-text {
  @apply w-max-content text-blue-400 font-semibold text-sm;
  @screen sm {
    @apply text-base;
  }
}
.router-link {
  @apply py-3 px-24 rounded-lg bg-blue-100 w-min-content;
}
</style>
