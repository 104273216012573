<template>
  <div>
    <h1 v-if="showActivityHeading" class="corporate-heading">
      {{ $t('PageActivity.Activity').value }}
    </h1>
    <template v-if="!loadingActivityList && !hasTransactions">
      <CorporateEmptyTransactionList v-if="isCorporateAccount && isCurrencyTransactionListEmpty" />
      <ConsumerWelcome v-else />
    </template>

    <AppSpinnerBig :loading="loadingActivityList" inline />

    <AppCard v-if="hasTransactions" class="activity-app-card">
      <h2 v-if="!isCorporateAccount" class="activity-title">
        {{ title }}
        <img
          class="welcome-img"
          :src="require(`@galileo/assets/images/illustrations/wave.png`)"
          alt="Waving hand icon"
        />
      </h2>

      <AppSkeleton
        v-if="loadingActivityList"
        class="activity-items-loading"
        :loading="loadingActivityList"
        height="80px"
        :count="3"
      />

      <template v-else>
        <div v-for="(category, idx) in transactionsByCategory" :key="idx">
          <div v-if="category.transactions.length > 0">
            <ActivityTransactionsList
              :loading-transaction-id="selectedSendAgainTransactionId"
              :category="category"
              @selectTransaction="selectTransaction"
              @provideInfo="transactionStore.provideInfo"
              @sendAgain="sendAgain"
              @showShare="() => transactionStore.showShareTransactionDialog()"
              @changeLoadingShareState="changeLoadingShareState"
            />
          </div>
        </div>
      </template>

      <div class="navigation">
        <AppButton
          v-if="currentPage > 1"
          theme="secondary"
          analytics-name="activity-prev"
          @click="showPrev"
        >
          {{ $t('PageActivity.ButtonPrevPage').value }}
        </AppButton>

        <AppButton
          v-if="currentPage < totalPages"
          theme="secondary"
          analytics-name="activity-next"
          @click="showNext"
        >
          {{ $t('PageActivity.ButtonNextPage').value }}
        </AppButton>
      </div>

      <div v-if="!loadingDetails" class="missing-details missing-details-small">
        <p>{{ $t('PageActivity.MissingDetailsText').value }}</p>

        <AppButton
          theme="secondary"
          analytics-name="activity-details-missing"
          @click="showMissingDetails"
        >
          {{ $t('PageActivity.MissingDetailsButton').value }}
        </AppButton>
      </div>
    </AppCard>

    <div
      v-if="!loadingDetails && !loadingActivityList && !isCorporateAccount"
      class="missing-details"
    >
      <p>{{ $t('PageActivity.MissingDetailsText').value }}</p>

      <AppButton
        theme="secondary"
        analytics-name="activity-details-missing"
        @click="showMissingDetails"
      >
        {{ $t('PageActivity.MissingDetailsButton').value }}
      </AppButton>
    </div>

    <TransferDetailsDialog
      v-if="showSelectedTransaction"
      @sendAgain="($event) => sendAgain($event)"
    />

    <SendMoneyProcessingTimesDialog v-model="isProcessingTimeDialogOpen" />

    <XeShareModal
      v-model="isShareDialogOpen"
      :title="$t('PageActivity.ShareHeader').value"
      @gmail="shareGmailHandler"
      @email="shareEmailHandler"
      @clipboard="copyClipboardHandler"
      @hide="transactionStore.hideShareTransactionDialog"
      :body="shareBody"
    />

    <EditTransactionDialog v-if="transactionStore.showEditTransaction" />

    <EditRecipientDetailsModal
      v-if="selectedRecipient && showModifyRecipientName"
      v-model="showModifyRecipientName"
      :recipient="selectedRecipient"
      edit-transaction
      @back="transactionStore.setModifyRecipientName(false)"
      @close="transactionStore.setModifyRecipientName(false)"
      @update="modifyRecipientAction($event)"
    />

    <RecipientNotFoundDialog v-if="showRecipientNotFoundDialog" />

    <PaymentMethodUnavailableModal />

    <!-- Send Again is not possible Dialog -->
    <AppDialogConfirmation
      v-model="showSendAgainNotPossibleDialog"
      analytics-name="send-again-not-possible-dialog"
      :title="$t('PageActivity.SendAgainNotPossibleDialogTitle').value"
      :cancel-btn-text="$t('PageActivity.SendAgainNotPossibleDialogCancelButtonText').value"
      :confirm-btn-text="$t('PageActivity.SendAgainNotPossibleDialogConfirmButtonText').value"
      @confirm="sendMoney"
    >
      <p>{{ $t('PageActivity.SendAgainNotPossibleDialogContent').value }}</p>
    </AppDialogConfirmation>

    <CancelTransferDialog />

    <CancellationSuccessDialog />

    <CancellationFailedDialog />

    <BankTransferTimeEstimateDialog />

    <ModifyFailedDialog />

    <XeNewLookModal />
  </div>
</template>

<script>
import { computed, onBeforeMount, onUnmounted, ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

// Stores
import {
  useI18nStore,
  useAuthStore,
  useActivityStore,
  useAppStore,
  useAnalyticsStore,
  useSendMoneyStore,
  useTransactionStore,
  storeToRefs,
} from '@galileo/stores'

// Components
import {
  AppButton,
  AppCard,
  useAlert,
  AppDialogConfirmation,
  AppSkeleton,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'
import ActivityTransactionsList from '@galileo/components/Views/Activity/ActivityTransactionsList'
import CorporateEmptyTransactionList from '@galileo/components/Views/Activity/CorporateEmptyTransactionList.vue'

// Dialogs
import {
  TransferDetailsDialog,
  CancellationFailedDialog,
  CancelTransferDialog,
  CancellationSuccessDialog,
  EditTransactionDialog,
  RecipientNotFoundDialog,
  PaymentMethodUnavailableModal,
  BankTransferTimeEstimateDialog,
  ModifyFailedDialog,
} from '@galileo/components/Views/Activity/Dialogs'

import EditRecipientDetailsModal from '@galileo/components/Views/Recipient/EditRecipientDetailsModal'
import SendMoneyProcessingTimesDialog from '@galileo/components/Views/SendMoney/SendMoneyProcessingTimesDialog'
import ConsumerWelcome from '@galileo/components/ConsumerWelcome/ConsumerWelcome'
import XeShareModal from '@galileo/components/XeShareModal/XeShareModal.vue'
import XeNewLookModal from '@galileo/components/XeNewLookModal/XeNewLookModal'

export default {
  components: {
    AppCard,
    AppSpinnerBig,
    AppButton,
    AppDialogConfirmation,
    ActivityTransactionsList,
    ConsumerWelcome,
    XeShareModal,
    TransferDetailsDialog,
    SendMoneyProcessingTimesDialog,
    CancellationFailedDialog,
    CancelTransferDialog,
    CancellationSuccessDialog,
    EditRecipientDetailsModal,
    EditTransactionDialog,
    RecipientNotFoundDialog,
    PaymentMethodUnavailableModal,
    BankTransferTimeEstimateDialog,
    ModifyFailedDialog,
    XeNewLookModal,
    AppSkeleton,
    CorporateEmptyTransactionList,
  },
  props: {
    isTabsShowing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { refs, emit }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const { isCorporateAccount } = useAuthStore()
    const activityStore = useActivityStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    const transactionStore = useTransactionStore()
    const { loadingDetails, isShareDialogOpen } = storeToRefs(transactionStore)

    const { add: addAlert } = useAlert()

    const hasTransactions = computed(() => activityStore.hasTransactions)

    const title = ref($t('PageActivity.WelcomeText').value)
    const selectedCurrency = ref(null)
    const showActivityHeading = !props.isTabsShowing && hasTransactions && isCorporateAccount

    const isCurrencyTransactionListEmpty = computed(
      () => !transactionStore.loadingActivityList && !activityStore.hasTransactions
    )

    onBeforeMount(async () => {
      selectedCurrency.value = appStore.currentRoute.params.currency
      transactionStore.setActivityListLoading()
      await activityStore.reloadTransactions(currentPage.value, selectedCurrency.value)
      transactionStore.setActivityListReady()
    })

    const sendMoney = async () => {
      sendMoneyStore.restoreDefaultForm()
      await sendMoneyStore.openSendMoney()
    }

    const modifyRecipientAction = async (recipientFields) => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSACTION_RECIPIENT_CHANGE_REQUESTED,
        traits: {
          location: SEGMENT_LOCATIONS.CASH_RECIPIENT_DETAILS,
        },
      })

      transactionStore.setActivityLoading()

      try {
        const { data } = await activityStore.modifyRecipientName({
          transaction: transactionStore.selectedTransactionDetails,
          recipientFields,
        })

        if (data?.success) {
          addAlert($t('PageActivity.ModifySuccessfulAlert').value)
        } else {
          appStore.logException('Failed to modify beneficiary', data)
          transactionStore.showModifyFailedDialog()
        }

        transactionStore.showModifyRecipientName = false
        transactionStore.hideSelectedTransactionModal()

        //reload transaction
        await activityStore.reloadTransactions()
      } catch (ex) {
        appStore.logException('Exception during edit transaction recipient', ex)
        transactionStore.showModifyFailedDialog()
      } finally {
        transactionStore.setActivityReady()
      }
    }

    // page navigation

    const currentPage = ref(1)

    const showPrev = async () => {
      transactionStore.setActivityLoading()

      const page = Math.max(1, currentPage.value - 1)
      await activityStore.reloadTransactions(page)
      currentPage.value = page

      transactionStore.setActivityReady()
    }

    const showNext = async () => {
      transactionStore.setActivityLoading()

      const page = Math.min(activityStore.getTransactionPages, currentPage.value + 1)
      await activityStore.reloadTransactions(page)
      currentPage.value = page

      transactionStore.setActivityReady()
    }

    const showMissingDetails = () => {
      router.push('/all-transactions')
    }

    const changeLoadingShareState = (value) => {
      transactionStore.loadingShare = value
    }

    const selectedSendAgainTransactionId = ref('')

    const sendAgain = async (transaction) => {
      selectedSendAgainTransactionId.value = transaction.id
      const nextPath = await transactionStore.sendAgain(transaction)
      if (nextPath) {
        router.replace(nextPath)
      }
      selectedSendAgainTransactionId.value = ''
    }

    onUnmounted(() => {
      transactionStore.resetSelectedTransaction()
    })

    const shareGmailHandler = async () => {
      activityStore.shareGmail(transactionStore.selectedTransactionDetails, loadingDetails)
    }
    const shareEmailHandler = async () => {
      activityStore.shareEmail(transactionStore.selectedTransactionDetails, loadingDetails)
    }

    const copyClipboardHandler = async () => {
      activityStore.shareClipboard(transactionStore.selectedTransactionDetails, loadingDetails)
      addAlert($t('ComponentSendMoneyShareTransferModal.CopyText').value)
    }

    const selectTransaction = (transaction) => {
      transactionStore.selectTransaction(transaction, router)
    }

    return {
      $t,
      hasTransactions,
      title,
      isCorporateAccount,
      showPrev,
      showNext,
      loadingDetails: computed(() => transactionStore.loadingDetails),
      transactionsByCategory: computed(() => activityStore.transactionsByCategory),
      currentPage,
      totalPages: computed(() => activityStore.getTransactionPages),
      showMissingDetails,
      changeLoadingShareState,
      loadingShare: computed(() => transactionStore.loadingShare),
      showSelectedTransaction: computed(() => transactionStore.showSelectedTransaction),
      selectedTransactionDetails: computed(() => transactionStore.selectedTransactionDetails),
      isProcessingTimeDialogOpen: computed(() => transactionStore.isProcessingTimeDialogOpen),
      showRecipientNotFoundDialog: computed(() => sendMoneyStore.shouldShowRecipientNotFoundModal),
      selectedRecipient: computed(() => transactionStore.selectedRecipient),
      showModifyRecipientName: computed(() => transactionStore.showModifyRecipientName),
      showSendAgainNotPossibleDialog: computed(
        () => transactionStore.showSendAgainNotPossibleDialog
      ),
      isCurrencyTransactionListEmpty,
      sendAgain,
      sendMoney,
      modifyRecipientAction,
      transactionStore,
      activityStore,
      //sahre
      isShareDialogOpen,
      shareGmailHandler,
      shareEmailHandler,
      copyClipboardHandler,
      shareBody: computed(() => transactionStore.shareBody),
      selectTransaction,
      loadingActivityList: computed(() => transactionStore.loadingActivityList),
      showActivityHeading,
      selectedSendAgainTransactionId,
    }
  },
}
</script>

<style scoped>
.activity-app-card {
  @apply w-full max-w-none;
}

.activity-title {
  @apply text-2xl mb-12;
  display: flex;
  align-items: center;
  font-weight: var(--theme-card-header-title-font-weight, theme('fontWeight.medium'));
}

.welcome-img {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  display: inline-block;
}

.navigation {
  display: flex;
  width: 100%;
  justify-content: space-around;

  button {
    max-width: 200px;
  }
}

.missing-details {
  @apply mt-4;
  @apply flex flex-col items-center;
  display: none;
  text-align: center;
}

.missing-details .button {
  @apply w-auto;
}

.missing-details-small {
  display: flex;
}

::v-deep .md-ripple {
  @apply z-0;
}

::v-deep .activity-items-loading {
  @apply mt-4;
  .skeleton {
    @apply rounded-lg mb-6 bg-gray-400;
  }
}

.corporate-heading {
  @apply max-w-5xl my-8 mx-3 text-lg font-semibold;
}

@screen sm {
  .missing-details {
    display: flex;
  }

  .missing-details-small {
    display: none;
  }

  .corporate-heading {
    @apply text-2xl mx-0;
  }
}
</style>
