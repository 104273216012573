<template>
  <InformationAppModal icon="checkmark" :value="showCancellationSuccessful">
    <h1>{{ $t('PageActivity.TitleCancelSuccessfulDialog').value }}</h1>

    <p>{{ $t('PageActivity.CancelDialogSuccessfulInfo').value }}</p>

    <template #footer>
      <AppButton analytics-name="activity-cancel-successful-ok" @click="hideCancellationSuccessful">
        {{ $t('PageActivity.CancelSuccessfulButtonOk').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { computed } from '@vue/composition-api'

// Stores
import { useI18nStore, useTransactionStore } from '@galileo/stores'

// Components
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'CancellationSuccessDialog',
  emits: ['hideCancellationSuccessful'],
  components: {
    InformationAppModal,
    AppButton,
  },
  setup() {
    const { $t } = useI18nStore()
    const transactionStore = useTransactionStore()

    const hideCancellationSuccessful = () => {
      transactionStore.showCancellationSuccessful = false
      transactionStore.showEditTransaction = false
    }

    return {
      $t,
      hideCancellationSuccessful,
      showCancellationSuccessful: computed(() => transactionStore.showCancellationSuccessful),
    }
  },
}
</script>

<style></style>
