<template>
  <AppModal
    v-model="showSelectedTransaction"
    :title="$t('PageActivity.DetailsTitleModal').value"
    @hide="transactionStore.setSelectedTransaction(null)"
  >
    <template #header>
      <AppCardHeader>
        <div class="w-100"></div>
        <template #right>
          <AppBackButton
            :name="$t('RecipientDetailsModal.CloseTooltipText').value"
            analytics-name="activity-details-back"
            @click="() => transactionStore.hideSelectedTransactionModal()"
          />
        </template>
      </AppCardHeader>
    </template>

    <XeTransferDetails
      v-if="selectedTransaction"
      :transaction="selectedTransaction"
      @loaded="($event) => transactionStore.loadedTransferDetails($event)"
      @sendAgain="$emit('sendAgain', selectedTransaction)"
      @showShare="() => transactionStore.showShareTransactionDialog()"
      @showProcessingTime="() => setProcessingTimeDialog(true)"
    />
    <template v-if="isBalanceTransaction" #footer>
      <AppCardFooter class="details-buttons">
        <!-- How long does it take -->
        <AppButton
          v-if="isHowLongDoesItTakeButtonShowing"
          analytics-name="activity-details-how-long-bank"
          @click="() => transactionStore.showBankTransferTimeEstimateDialog()"
        >
          {{ $t('PageActivity.DetailsButtonHowLongBank').value }}
        </AppButton>
      </AppCardFooter>
    </template>

    <template v-else #footer>
      <AppCardFooter class="details-buttons">
        <AppButton
          v-if="isOfflineContract"
          analytics-name="activity-provide-info"
          @click="transactionStore.provideInfo(selectedTransaction)"
          :loading="transactionStore.loading"
        >
          {{ $t('PageActivity.DetailsButtonProvideInfo').value }}
        </AppButton>

        <AppButton
          v-if="!isOfflineContract && (allowModifyRecipient || allowCancel)"
          theme="secondary"
          analytics-name="activity-details-edit"
          @click="() => transactionStore.showEditTransactionDialog()"
          :loading="transactionStore.loading"
        >
          {{ $t('PageActivity.DetailsButtonEdit').value }}
        </AppButton>

        <AppButton
          v-if="!isOfflineContract && allowShare"
          analytics-name="activity-details-share"
          @click="transactionStore.showShareTransactionDialog()"
        >
          {{ $t('PageActivity.DetailsButtonShare').value }}
        </AppButton>

        <AppButton
          :loading="loadingDetails || transactionStore.loading"
          v-if="!isOfflineContract && allowSendAgain"
          analytics-name="activity-details-send-again"
          @click="$emit('sendAgain', selectedTransactionDetails)"
        >
          {{ $t('PageActivity.DetailsButtonSendAgain').value }}
        </AppButton>

        <AppButton
          v-if="!isOfflineContract && allowContactUs && !allowProvideDetails"
          analytics-name="activity-details-contact-us"
          :loading="loadingContactUs"
          @click="showContactUs()"
        >
          {{ $t('PageActivity.DetailsButtonContactUs').value }}
        </AppButton>

        <AppButton
          v-if="!isOfflineContract && allowContactUs && allowProvideDetails"
          theme="secondary"
          analytics-name="activity-details-contact-us"
          :loading="loadingContactUs"
          @click="showContactUs()"
        >
          {{ $t('PageActivity.DetailsButtonHelpCentre').value }}
        </AppButton>

        <AppButton
          v-if="!isOfflineContract && allowProvideDetails"
          analytics-name="activity-details-provide-details"
          @click="() => showProvideDetails()"
          :loading="loadingDetails || transactionStore.loading"
        >
          {{ $t('PageActivity.DetailsButtonProvideDetails').value }}
        </AppButton>

        <AppButton
          v-if="!isOfflineContract && canShowBankTransferTimeEstimateDialog"
          analytics-name="activity-details-how-long-bank"
          @click="() => transactionStore.showBankTransferTimeEstimateDialog()"
        >
          {{ $t('PageActivity.DetailsButtonHowLongBank').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppModal>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import XeTransferDetails from '@galileo/components/XeTransferDetails/XeTransferDetails'

import {
  AppButton,
  AppCard,
  useAlert,
  AppCardHeader,
  AppModal,
  AppBackButton,
  AppCardFooter,
  useVModel,
} from '@oen.web.vue2/ui'

import {
  useActivityStore,
  useAppStore,
  useI18nStore,
  useTransactionStore,
  useSendMoneyStore,
} from '@galileo/stores'
import {
  ACTION_REQUIRED,
  TRANSACTION_ACTIVITY_STATUSES,
} from '@galileo/constants/transactionStatuses'
import { DELIVERY_METHODS, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  components: {
    AppButton,
    AppCard,
    useAlert,
    AppCardHeader,
    AppModal,
    AppBackButton,
    AppCardFooter,
    XeTransferDetails,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const appStore = useAppStore()
    const activityStore = useActivityStore()
    const transactionStore = useTransactionStore()
    const sendMoneyStore = useSendMoneyStore()
    const allowModifyRecipient = computed(() => {
      const transaction = activityStore.transactionDetails
      return transaction && transaction.modifiable && transactionStore.allowCashPickupModification
    })

    onBeforeMount(() => {
      transactionStore.loading = false
    })

    const loadingContactUs = ref(false)

    const showContactUs = () => {
      appStore.openHelpDesk({ loadingRef: loadingContactUs })
    }

    const isBalanceTransaction = computed(() => {
      const deliveryMethod = transactionStore.selectedTransaction.deliveryMethod.enumName
      const paymentMethod = transactionStore.selectedTransaction.paymentMethod.value
      return deliveryMethod === 'FundsOnBalance' || paymentMethod === 'FundsOnBalance'
    })

    const isOnHold = transactionStore.selectedTransaction.transactionDetailStatus === 'onHold'
    const isAwaitingFunds =
      transactionStore.selectedTransaction.transactionDetailStatus === 'AwaitingBalance'

    const goToBalancePage = () => {
      router.push(router.push(`/balances/${selectedTransaction.currencyTo}`))
    }

    const showProvideDetails = async () => {
      if (transactionStore.selectedTransaction.actionsRequiredType !== ACTION_REQUIRED.NONE) {
        transactionStore.loading = true

        await sendMoneyStore.goToOfflineToOnlineContract({
          transaction: transactionStore.selectedTransaction,
          location: SEGMENT_LOCATIONS.TRANSFER_DETAILS,
        })
      } else {
        activityStore.setSelectedOrderNumber(transactionStore.selectedTransaction.orderNumber)
        router.push('/activity/provide-recipient')
      }
    }

    const isHowLongDoesItTakeButtonShowing = computed(
      () =>
        transactionStore.selectedTransaction.transactionActivityStatus !==
        TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name
    )

    return {
      $t,
      transactionStore,
      allowModifyRecipient,
      showSelectedTransaction: computed(() => transactionStore.showSelectedTransaction),
      isOfflineContract: computed(() => transactionStore.isOfflineContract),
      allowCancel: computed(() => transactionStore.allowCancel),
      selectedTransaction: computed(() => transactionStore.selectedTransaction),
      transactionDetailsTitle: computed(() => transactionStore.transactionDetailsTitle),
      allowShare: computed(() => transactionStore.allowShare),
      allowSendAgain: computed(() => transactionStore.allowSendAgain),
      allowContactUs: computed(() => transactionStore.allowContactUs),
      allowProvideDetails: computed(() => transactionStore.allowProvideDetails),
      canShowBankTransferTimeEstimateDialog: computed(
        () => transactionStore.canShowBankTransferTimeEstimateDialog
      ),
      selectedTransactionDetails: computed(() => transactionStore.selectedTransactionDetails),
      loadingDetails: computed(() => transactionStore.loadingDetails),
      showContactUs,
      showProvideDetails,
      isBalanceTransaction,
      isOnHold,
      isAwaitingFunds,
      goToBalancePage,
      isHowLongDoesItTakeButtonShowing,
    }
  },
}
</script>

<style scoped>
.details-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .button {
    flex-grow: 2;
    max-width: 100%;
    width: fit-content;
    white-space: nowrap;
    margin-top: 10px;
    @apply mx-1;
  }
  @screen sm {
    .button + .button {
      z-index: 0;
    }
  }
}
::v-deep .card-header {
  padding-bottom: 0px;
}
</style>
