<template>
  <div>
    <AppSpinnerBig :loading="isLoadingBalance" />
    <div v-if="selectedBalance" class="component-wrapper">
      <button @click="backButtonHandler" class="back-button">
        <IconLeftArrow />
        <p>
          {{ $t('Balances.IndividualCurrency.Back').value }}
        </p>
      </button>
      <div class="top-items-wrapper">
        <div class="left-items">
          <div class="currency-heading">
            <CircularFlag
              size="36"
              class="country-flag"
              :currency-code="selectedBalance.accountCurrency"
            />

            <h2 class="currency-name">
              {{ selectedBalance.accountCurrency }}
            </h2>
          </div>
          <p class="currency-label">
            {{ $t('Balances.IndividualCurrency.Total').value }}
            {{ selectedBalance.accountCurrency }}
            {{ $t('Balances.IndividualCurrency.Balance').value }}
          </p>
          <p class="currency-amount">
            {{ formattedTotalAmount }} {{ selectedBalance.accountCurrency }}
          </p>
          <p class="currency-available">
            {{ formattedAvailableAmount }}
            {{ $t('Balances.IndividualCurrency.Available').value }}
          </p>
        </div>
        <TransactionShortcutButtons />
      </div>
      <ActivityCard class="mt-8" />
    </div>
  </div>
</template>

<script>
import {
  useBalancesStore,
  useDeviceStore,
  useI18nStore,
  useAppStore,
  useTransactionStore,
  useActivityStore,
} from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import { computed, ref, onBeforeMount } from '@vue/composition-api'
import { getFlag } from '@galileo/composables/useFlag'
import { AppSpinnerBig } from '@oen.web.vue2/ui'
import { IconPurchase, IconFinance, IconMoney } from '@oen.web.vue2/icons'
import { IconLeftArrow } from '@galileo/assets/icons/vue'
import TransactionShortcutButtons from '@galileo/components/Views/Balances/TransactionShortcutButtons/TransactionShortcutButtons'
import { formatCurrency } from '@galileo/composables/useCurrency'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'
import ActivityCard from '@galileo/components/Views/Activity/ActivityCard.vue'
import CorporateEmptyTransactionList from '@galileo/components/Views/Activity/CorporateEmptyTransactionList.vue'

export default {
  name: 'IndividualCurrency',
  components: {
    CircularFlag,
    IconLeftArrow,
    ActivityCard,
    TransactionShortcutButtons,
    CorporateEmptyTransactionList,
    AppSpinnerBig,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const balancesStore = useBalancesStore()
    const appStore = useAppStore()
    const transactionStore = useTransactionStore()
    const activityStore = useActivityStore()
    const deviceStore = useDeviceStore()

    const selectedBalance = ref(null)
    const selectedCurrency = ref('')

    const formattedTotalAmount = ref('')
    const formattedAvailableAmount = ref('')

    const isLoadingBalance = ref(true)

    onBeforeMount(async () => {
      if (
        appStore.currentRoute.from.name !== 'FundBalance' &&
        appStore.currentRoute.from.name !== 'ConvertBalances' &&
        appStore.currentRoute.from.name !== 'OneStepTransfer'
      ) {
        appStore.accessIndividualCurrencyFromPath = appStore.currentRoute.from.path
      }
      selectedCurrency.value = appStore.currentRoute.params.currency
      await balancesStore.fetchTradableBalanceList()
      isLoadingBalance.value = false
      selectedBalance.value = balancesStore.tradableBalanceList.find(
        (b) => b.accountCurrency === selectedCurrency.value
      )
      if (!selectedBalance.value) {
        router.push({ name: 'NotFound' })
        return
      }

      formattedTotalAmount.value = formatCurrency(
        selectedBalance.value.accountCurrency,
        selectedBalance.value.totalBalance,
        deviceStore.locale
      )
      formattedAvailableAmount.value = formatCurrency(
        selectedBalance.value.accountCurrency,
        selectedBalance.value.availableBalance,
        deviceStore.locale
      )
    })

    const backButtonHandler = () => {
      router.replace({ path: appStore.accessIndividualCurrencyFromPath })
    }

    return {
      $t,
      selectedBalance,
      getFlag,
      formatCurrency,
      backButtonHandler,
      formattedTotalAmount,
      formattedAvailableAmount,
      isLoadingBalance,
    }
  },
}
</script>

<style scoped>
.back-button {
  @apply flex flex-row items-center gap-2 my-8 mx-4 w-max-content text-grayblue-300 font-semibold;
}
.component-wrapper {
  @apply flex flex-col w-full max-w-5xl mx-auto;
}
.top-items-wrapper {
  @apply flex flex-row flex-wrap mb-6 mx-4 gap-6;
}
.left-items {
  @apply flex-grow;
}
.currency-heading {
  @apply flex flex-row items-center gap-2 mb-6;
}
.app-flag {
  @apply my-1.5;
}
.currency-name {
  @apply text-2xl  font-semibold text-grayblue-400;
}
.currency-label {
  @apply text-grayblue-300 mb-2;
}
.currency-amount {
  @apply text-grayblue-400  text-3xl font-semibold mb-4;
}
.currency-available {
  @apply text-sm text-grayblue-300 rounded bg-gray-400 px-2 w-max-content font-semibold;
}
.activity-heading {
  @apply text-xl text-grayblue-400 font-semibold mx-4;
}

@screen sm {
  .component-wrapper {
    @apply px-5;
  }
  .currency-name {
    @apply text-3xl;
  }
  .currency-amount {
    @apply text-4xl;
  }
  .activity-heading {
    @apply text-2xl;
  }
  .back-button {
    @apply mt-0;
  }
}
</style>
