<template>
  <AppInputDropdown
    v-model="model"
    :label="label"
    class="countries"
    :options="currencies"
    :border="border"
    v-bind="$attrs"
    :selected-display-field="selectedDisplayField"
    selected-value-field="value"
    :validation="validation"
    :filter="true"
    :check-additional-field="currencyFullNameAfterIcon"
    additional-field-name="text"
  >
    <template #selected="{ value: templateValue, option }">
      <div class="currency-item">
        <span v-if="preText" class="currency-pre-text">{{ preText }}</span>
        <span v-if="!currencyNameAfterIcon" class="currency-name-before-icon">{{
          option.text
        }}</span>
        <AppFlag class="country-flag" v-if="!useCircularFlags">
          <Component :is="getFlag(getCountry(templateValue))" />
        </AppFlag>

        <CircularFlag v-else size="24" :currency-code="getCountry(templateValue).value" />
        <span v-if="currencyNameAfterIcon" class="currency-name-after-icon">
          {{ option.value }}
          <span v-if="currencyFullNameAfterIcon" class="currency-name-after-icon"
            >&nbsp;-&nbsp;{{ option[selectedDisplayField] }}</span
          >
        </span>
      </div>
    </template>
    <template #option="{ option }">
      <div class="currency-item">
        <AppFlag v-if="!useCircularFlags" class="country-flag">
          <Component :is="getFlag(option)" />
        </AppFlag>
        <CircularFlag v-else size="24" :currency-code="option.value" />

        <span class="currency-name-after-icon">
          {{ option.value }}
          <span v-if="currencyFullNameAfterIcon" class="currency-full-name-after-icon"
            >&nbsp;-&nbsp;{{ option[selectedDisplayField] }}</span
          >
        </span>
      </div>
    </template>
    <template #no-search-results> {{ $t('AddressSearch.noResult').value }}</template>
  </AppInputDropdown>
</template>

<script>
import { computed } from '@vue/composition-api'
import * as EuronetFlags from '@oen.web.vue2/flags'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'
import { useComponentId, useValidationHelpers, AppInputDropdown, AppFlag } from '@oen.web.vue2/ui'

import { useI18nStore } from '@galileo/stores'

export default {
  name: 'XeInputCurrency',
  emits: ['input'],
  components: {
    AppInputDropdown,
    AppFlag,
    CircularFlag,
  },
  props: {
    value: {
      type: String,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    currencies: {
      type: Array,
      required: true,
      default: () => [],
    },
    validation: {
      type: Object,
      default: () => {},
    },
    preText: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
    },
    currencyNameAfterIcon: {
      type: Boolean,
      default: true,
    },
    currencyFullNameAfterIcon: {
      type: Boolean,
      default: true,
    },
    selectedDisplayField: {
      type: String,
      default: 'text',
      required: false,
    },
    useCircularFlags: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    })

    const { $t } = useI18nStore()

    const id = useComponentId()
    const validationHelpers = useValidationHelpers(props.validation)

    const getFlag = (option) => {
      let flag = null
      if (option) {
        if (option.flag) {
          flag = 'Flag' + option.flag.toUpperCase()
        } else if (option.value) {
          // TODO: temp fix for flags
          flag = 'Flag' + option.value.toUpperCase().substring(0, 2)
        }
      }
      if (flag === 'FlagKV') {
        flag = 'FlagCV'
      }
      return EuronetFlags[flag] || null
    }

    const getCountry = (value) => {
      return props.currencies.find((currency) => currency.value === value)
    }

    const getCurriencyText = (option) => {
      if (option) {
        return option.text
      }
      return ''
    }

    return {
      model,
      id,
      validationHelpers,
      getFlag,
      getCountry,
      getCurriencyText,
      $t,
    }
  },
}
</script>

<style scoped>
.currency-item {
  @apply flex flex-row items-center;
}

.currency-pre-text {
  @apply mr-1;
}

.currency-name-before-icon {
  @apply mr-2;
  @apply font-bold;
}

.currency-name-after-icon {
  @apply ml-2;
}

.currency-full-name-after-icon {
  @apply text-gray-text;
}
</style>
