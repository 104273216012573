<template>
  <InformationAppModal icon="warningYellow" :value="showRecipientNotFoundDialog">
    <h1>{{ $t('Activity.RecipientNotFoundTitle').value }}</h1>

    <p>{{ $t('Activity.RecipientNotFoundContent').value }}</p>

    <template #footer>
      <AppButton
        :loading="loadingNewTransaction"
        analytics-name="activity-cancel-transfer-yes"
        @click="startNewTransfer"
      >
        {{ $t('Activity.StartANewTransaction').value }}
      </AppButton>

      <AppButton
        analytics-name="activity-cancel-transfer-no"
        theme="text"
        @click="hideRecipientNotFoundDialog"
      >
        {{ $t('Activity.Dismiss').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

// Stores
import { useI18nStore, useAnalyticsStore, useSendMoneyStore } from '@galileo/stores'

// Components
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'RecipientNotFoundDialog',
  emits: ['startNewTransfer', 'hideRecipientNotFoundDialog'],
  components: {
    InformationAppModal,
    AppButton,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()

    const router = useRouter()

    const loadingNewTransaction = ref(false)

    const startNewTransfer = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.INVALID_RECIPIENT_POPUP_ACK,
        traits: {
          result: 'New transfer',
        },
      })

      loadingNewTransaction.value = true

      //fix the quote first
      await sendMoneyStore.calculate({ summary: false, debounceTime: 0 })
      router.push({ path: '/send-money/resend', replace: true })
    }

    const hideRecipientNotFoundDialog = () => {
      sendMoneyStore.shouldShowRecipientNotFoundModal = false

      analyticsStore.track({
        event: SEGMENT_EVENTS.INVALID_RECIPIENT_POPUP_ACK,
        traits: {
          result: 'Dismiss',
        },
      })
    }

    return {
      $t,
      startNewTransfer,
      hideRecipientNotFoundDialog,
      showRecipientNotFoundDialog: computed(() => sendMoneyStore.shouldShowRecipientNotFoundModal),
      loadingNewTransaction,
    }
  },
}
</script>
