<template>
  <AppDialog v-model="transactionStore.showEditTransaction" :class="blur">
    <template #header>
      <AppCardHeader>
        <template #default>{{ $t('PageActivity.EditTransactionHeader').value }}</template>

        <template #right>
          <XeBackButton
            analytics-name="activity-edit-transaction-close"
            @click="() => transactionStore.hideEditTransactionDialog()"
          />
        </template>
      </AppCardHeader>
    </template>

    <AppList>
      <XeListItem
        :text="$t('PageActivity.ButtonModifyRecipient').value"
        v-if="allowModifyRecipient"
        :select="modifyRecipient"
      >
        <template #figure-icon>
          <AppIcon name=" ">
            <IconEdit />
          </AppIcon>
        </template>
      </XeListItem>

      <XeListItem
        :text="$t('PageActivity.ButtonCancelTransaction').value"
        v-if="isCancellable"
        :select="cancelTransfer"
        icon="IconClose"
      >
        <template #figure-icon>
          <AppIcon name=" ">
            <IconClose />
          </AppIcon>
        </template>
      </XeListItem>
    </AppList>
  </AppDialog>
</template>

<script>
import { computed } from '@vue/composition-api'
// Stores
import { useI18nStore, useAppStore, useTransactionStore } from '@galileo/stores'

// Components
import { AppDialog, AppCardHeader, AppList, AppIcon, useTransition } from '@oen.web.vue2/ui'
import XeListItem from '@galileo/components/XeListItem/XeListItem'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import { IconEdit, IconClose } from '@oen.web.vue2/icons'

export default {
  name: 'EditTransactionDialog',
  components: {
    AppDialog,
    AppCardHeader,
    AppList,
    AppIcon,
    XeListItem,
    IconEdit,
    IconClose,
    XeBackButton,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const transactionStore = useTransactionStore()

    const blur = useTransition('blur', transactionStore.loadingDetails)

    const modifyRecipient = async () => {
      transactionStore.setActivityLoading()
      try {
        transactionStore.selectedRecipient = await recipientsStore.getCashRecipient(
          transactionStore.selectedTransactionDetails.recipientId
        )
        transactionStore.hideEditTransactionDialog()

        transactionStore.showModifyRecipientName = true
      } catch (ex) {
        appStore.logException('Exception during cancel transfer', ex)
        appStore.messageBoxGenericError()
      } finally {
        transactionStore.setActivityReady()
      }
    }

    return {
      $t,
      blur,
      allowModifyRecipient: computed(() => transactionStore.getIsEditable),
      isCancellable: computed(() => transactionStore.getIsCancellable),
      modifyRecipient,
      cancelTransfer: () => transactionStore.showCancelTransactionDialog(),
      transactionStore,
    }
  },
}
</script>

<style></style>
