<template>
  <InformationAppModal :value="bankTransferTimeEstimateDialogVisible" icon="moneyWings">
    <h1 class="title">{{ $t('PageActivity.HowLongBankTitle').value }}</h1>

    <p class="text-left">{{ $t('PageActivity.HowLongBankText').value }}</p>

    <template #footer>
      <AppButton @click="closeHowLongBank">
        {{ $t('PageActivity.ButtonGotIt').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
//vue
import { computed } from '@vue/composition-api'

// Stores
import { useI18nStore, useTransactionStore } from '@galileo/stores'

// Components
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'BankTransferTimeEstimateDialog',
  components: {
    AppButton,
    InformationAppModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const transactionStore = useTransactionStore()

    const closeHowLongBank = () => {
      transactionStore.hideBankTransferTimeEstimateDialog()
    }

    return {
      $t,
      bankTransferTimeEstimateDialogVisible: computed(
        () => transactionStore.bankTransferTimeEstimateDialogVisible
      ),
      closeHowLongBank,
    }
  },
}
</script>
