<template>
  <InformationAppModal icon="crossmark" :value="showModifyFailed">
    <h1>{{ $t('PageActivity.TitleModifyFailedDialog').value }}</h1>

    <p>{{ $t('PageActivity.ModifyDialogFailedInfo').value }}</p>

    <template #footer>
      <AppButton analytics-name="activity-modify-failed-ok" theme="text" @click="hideModifyFailed">
        {{ $t('PageActivity.ModifyFailedButtonOk').value }}
      </AppButton>

      <AppButton
        analytics-name="activity-modify-failed-help"
        :loading="loadingHelpDesk"
        @click="showHelpDesk"
      >
        {{ $t('PageActivity.ModifyFailedButtonHelp').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

// Stores
import { useI18nStore, useAppStore, useTransactionStore } from '@galileo/stores'

// Components
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'ModifyFailedDialog',
  components: {
    InformationAppModal,
    AppButton,
  },
  setup() {
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const transactionStore = useTransactionStore()

    const loadingHelpDesk = ref(false)
    const showHelpDesk = () => {
      appStore.openHelpDesk({ loadingRef: loadingHelpDesk })
    }

    const hideModifyFailed = () => {
      transactionStore.showModifyRecipientName = false
      transactionStore.hideModifyFailedDialog()
    }

    return {
      $t,
      loadingHelpDesk,
      showModifyFailed: computed(() => transactionStore.showModifyFailed),
      showHelpDesk,
      hideModifyFailed,
    }
  },
}
</script>

<style></style>
