<template>
  <AppCard sheet class="no-transactions">
    <XeCardImageTitle
      :colorable="false"
      :title="$t('PageActivity.WelcomeText').value"
      :sub-title="subTitle"
      :src="require('@galileo/assets/images/handwave.png')"
      class="activity-empty-image"
      alt="User with world"
    >
      <p class="sendMoneyText">{{ $t('PageActivity.SendMoneyText').value }}</p>

      <AppButton
        analytics-name="activity-send-money"
        class="send-money-button"
        theme="primary"
        @click="sendMoney"
      >
        {{ $t('PageActivity.ButtonSendMoney').value }}
      </AppButton>
    </XeCardImageTitle>
  </AppCard>
</template>
<script>
import { AppButton, AppCard } from '@oen.web.vue2/ui'

import { useSendMoneyStore, useI18nStore, useAuthStore } from '@galileo/stores'
import XeCardImageTitle from '@galileo/components/XeCardImageTitle/XeCardImageTitle'
import { computed } from '@vue/composition-api'

export default {
  name: 'ConsumerWelcome',
  components: {
    AppCard,
    XeCardImageTitle,
    AppButton,
  },

  setup() {
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()

    const { user } = authStore
    const { $t } = useI18nStore()

    const subTitle = user?.customer?.firstName

    const sendMoney = async () => {
      sendMoneyStore.restoreDefaultForm()
      await sendMoneyStore.openSendMoney()
    }

    return { sendMoney, $t, subTitle }
  },
}
</script>

<style>
.sendMoneyText {
  @apply mb-6;
  @apply px-6;
}

.card.no-transactions {
  @screen xs {
    width: 98%;
    margin: 0px 1%;
  }
  @screen sm {
    width: unset;
    margin: auto;
  }
}
.no-transactions {
  ::v-deep .card-content-block {
    @apply flex flex-col items-center;
  }
}

.activity-empty-image {
  ::v-deep .card-image-title-image {
    @apply mb-0;
    @apply pb-8;
  }
}
</style>
